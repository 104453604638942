
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { IOrder } from "@/types/order";
import { IMovement, IMovementListRequest } from "@/types/movement";
import {
  TransportUnitPrice,
  TransportUnitPriceUpdateRequest
} from "@/models/stock";
import {
  ITransportUnitPrice,
  ITransportUnitPriceUpdateRequest
} from "@/types/stock";

@Component({})
export default class extends Vue {
  //movement
  @Action("movement/adminGetList")
  public getMovementList!: (request: IMovementListRequest) => Promise<boolean>;

  @Getter("movement/list")
  public movementList!: IMovement[];

  @Mutation("movement/clear")
  public clearMovement!: () => void;

  //order
  @Action("order/adminGet")
  public getOrder!: (id: number) => void;

  @Getter("order/single")
  public order!: IOrder;

  @Mutation("order/clear")
  public clearOrder!: () => void;

  //stock
  @Action("stock/adminUpdateTransportUnitPrice")
  public update!: (
    params: ITransportUnitPriceUpdateRequest
  ) => Promise<boolean>;

  //パラメータ定義
  public params: ITransportUnitPriceUpdateRequest =
    new TransportUnitPriceUpdateRequest();

  //発注ステータス
  public order_status_list = [
    { value: Vue.prototype.$orderStatus.order_registration, text: "発注登録" },
    { value: Vue.prototype.$orderStatus.ordered, text: "発注登録" },
    { value: Vue.prototype.$orderStatus.wait_storing, text: "入荷待ち" },
    { value: Vue.prototype.$orderStatus.stored, text: "入荷済み" },
    { value: Vue.prototype.$orderStatus.cancel_order, text: "発注キャンセル" },
    { value: Vue.prototype.$orderStatus.division_storing, text: "分納" }
  ];

  //変数定義
  public order_id = 0;
  public transport_price: number | null = null;
  public check_list: boolean[] = [];
  public all_check = false;
  public disp = false;
  public submit_dialog = false;
  public valid = true;
  public lazy = false;

  //ルール設定
  public rules = {
    transport_price: [
      (v: number) => !v || v >= 0 || "0未満は入力できません",
      (v: string) =>
        !v ||
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    transport_unit_price: [
      (v: number) => !v || v >= 0 || "0未満は入力できません",
      (v: string) =>
        !v ||
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.order_id = Number(this.$route.params.order_id);
    this.clearMovement();
    this.clearOrder();

    await Promise.all([
      this.getOrder(this.order_id),
      this.getMovementList({ order_id: this.order_id })
    ]);

    this.setDefault();

    this.disp = true;
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    for (let [, movement] of this.movementList.entries()) {
      const params: ITransportUnitPrice = {
        stock_id: movement.stock_id,
        transport_unit_price: movement.transport_unit_price || null
      };
      this.params.stocks.push(new TransportUnitPrice(params));
      this.check_list.push(false);
    }
  }

  //--------
  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    //transport_unit_priceのnullを0にする
    for (let [, param] of this.params.stocks.entries()) {
      if (param.transport_unit_price == null) {
        param.transport_unit_price = 0;
      }
    }

    // 輸送費単価の更新
    result = await this.update(this.params);

    // ひとつ前の画面に遷移
    if (result) {
      this.$router.go(-1);
    }
  }

  //輸送費単価計算
  public calcTransportUnitPrice() {
    let storing_quantity = 0;
    let transport_unit_price = 0;

    if (!this.transport_price) return;

    for (let [index, movement] of this.movementList.entries()) {
      if (this.check_list[index]) {
        storing_quantity += Number(movement.quantity);
      }
    }

    transport_unit_price = Number(
      (this.transport_price / storing_quantity).toFixed(2)
    );

    for (let [index, param] of this.params.stocks.entries()) {
      if (this.check_list[index]) {
        param.transport_unit_price = transport_unit_price;
      }
    }
  }

  public allCheck() {
    if (this.all_check) {
      this.check_list.fill(true);
    }
    if (!this.all_check) {
      this.check_list.fill(false);
    }
  }
}
